.wheel-gallery {
  &__header {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.105;
    @include breakpoint(small only) {
      margin-top: 1rem;
    }
  }
}

.wheel-galleryList {
  margin: 0;
  @include breakpoint(small only) {
    margin-left: 0.5rem;
  }
  &__item {
    list-style: none;
    float: left;
    width: 31%;
    height: auto;
    margin-right: 2%;
    margin-top: 0.5rem;
    &:nth-child(n+7) {
      display: none;
    }
  }
}

.wheel-galleryImage {
  width: 190px;
  height: 102px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @include breakpoint(small only) {
    width: 95px;
    height: 60px;
  }
}

@include breakpoint(small only) {
  .wheel-galleryTxt {
    font-size: 0.8rem;
  }
}