.gallery-popup-quote {
    &__header {
        font-size: 1.9rem;
    }
    &__txt {
        font-size: 0.95rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
        @include breakpoint(small only) {
            line-height: 1.2;
        }
    }
    &__note {
        font-size: 0.8rem;
        font-weight: 500;
        margin-bottom: 0;
    }
    label {
        font-size: 1rem;
        font-weight: 700;
    }
    input:not([type='submit']) {
        background-color: #eee;
        box-shadow: none;
        border: none;
        color: #000;
    }
}

.form-name {
    display: flex;
    justify-content: space-between;
    @include breakpoint(small only) {
        flex-direction: column;
    }
    input {
        width: 19.5rem;
        @include breakpoint(small only) {
            width: 100%
        }        
        &::placeholder {
            color: #333;
            font-size: 0.9rem;
        }
    }
}

.gallery-product-info {
    span {
        font-weight: 400;
    }
}
