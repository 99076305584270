.gallery-title {
    font-size: 2rem;
    margin: 0;
}
#gallery-main-image {
    margin-bottom: 1rem;
    img {
        width: 100%;
    }
}
.gallery-thumb {
    margin-bottom: 1rem;
}
#gallery-details {
    margin-bottom: 2rem;
}
#gallery-thumbs .slick-dots {
    bottom: 0!important;
    position: relative;
    margin: 0;
}