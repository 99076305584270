.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.4;    
    background-color: black;
}

.t {
    &-uppercase {
        text-transform: uppercase;
    }
    &-center {
        text-align: center;
        &--sm {
            @include breakpoint(small only) {
                text-align: center;
            }
        }
    }
    &-right {
        text-align: right;
    }
}

.color {
    &--gray {
        color: $medium-gray;
    }
}

.margin {
    &--tb {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

.padding {
    &--tb {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}