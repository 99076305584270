#gallery {
	.hover-list {
		.photo {
			position: relative;
			display: block;
			padding: 0 1px 1px 0;
			&:before {
				position: absolute;
				z-index: 1;
				display: block;
				width: 100%;
				height: 100%;
				content: '';
				-webkit-transition-timing-function: linear;
				transition-timing-function: linear;
				-webkit-transition-duration: .3s;
				transition-duration: .3s;
				-webkit-transition-property: all;
				transition-property: all;
				background: rgba(255, 255, 255, 0);
			}
			&:after {
				font-size: 1.1rem;
				position: absolute;
				z-index: 2;
				top: 50%;
				display: block;
				width: 90%;
				margin: -1em 5% 0;
				padding-bottom: 2em;
				content: attr(title);
				-webkit-transition-timing-function: linear;
				transition-timing-function: linear;
				-webkit-transition-duration: .2s;
				transition-duration: .2s;
				-webkit-transition-property: all;
				transition-property: all;
				text-align: center;
				letter-spacing: 1px;
				text-transform: uppercase;
				opacity: 0;
				color: #d40000;
				filter: alpha(opacity=0);
			}
			&:hover:before {
				background: rgba(255, 255, 255, .9);
			}
			&:hover:after {
				opacity: 1;
			}
		}
		.video:after {
			font-weight: 300;
			color: #fefefe;
		}
		.video:hover:before {
			background: rgba(223, 61, 61, .9);
		}
	}
}