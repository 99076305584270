// ********** HERO VEHICLE SELECT ********** //
.vehicle-select {
  background-color: $white;
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
    @include breakpoint(large) {
      padding: 1rem 0;
      border-bottom: 1px solid $light-gray;
    }
  }
  &__header {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
  }
  &__icon {
    color: $primary-color;
  }
  &__links {
    margin-bottom: 1rem;
    a {
      color: $primary-color;
      font-size: 0.9rem;
      font-weight: 500;
      font-style: italic;
      text-transform: uppercase;
      text-decoration: underline;
      @include breakpoint(large) {
        font-size: 1rem;
      }
    }
  }
  &__bottom {
    padding-top: 1rem;
    font-weight: 700;
    @include breakpoint(large) {
      padding: 1rem 0;
    }
  }
  .select {
    border-radius: 3px;
    border-width: 2px;
    &:disabled {
      background-color: $light-gray;
      color: $medium-gray;
    }
  }
}

// ********** ACMIA BANNER ********** //
.acmia-banner-link {
  display: block;
}

.acmia-banner-img {
  width: 100%;
}

// ********** CALL TO ACTION BAR ********** //
.cta-top {
  background-color: $primary-color;
  &__header {
    color: $white;
    font-size: 1.2rem;
    line-height: 1.2;
    margin: 0;
    padding: 0.5rem 1.5rem;
    @include breakpoint(large) {
      font-size: 1.5rem;
      padding: 1rem 0;
    }
  }
}

.cta-bottom {
  position: relative;
  &__links {
    position: absolute;
    top: 50%;
    bottom: 0;
    transform: translateY(-50%);
    width: 100%;
    display: block;
    @include breakpoint(large) {
      top: 0;
      transform: none;
    }
  }
  &__img {
    width: 100%;
  }
  &__column {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    @include breakpoint(large) {
      margin-top: 0;
      margin-bottom: 0;
      height: 100%;
    }
  }
}

.cta-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @include breakpoint(medium) {
    &--left {
      clip-path: polygon(0 0, 100% 0%, 84% 99%, 0 100%);
      -webkit-clip-path: polygon(0 0, 100% 0%, 84% 99%, 0 100%);
    }
    &--mid {
      clip-path: polygon(0 0, 90% 0%, 100% 100%, 5.5% 100%);
      -webkit-clip-path: polygon(0 0, 90% 0%, 100% 100%, 5.5% 100%);
    }
    &--right {
      clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0 100%);
      -webkit-clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0 100%);
    }
  }
  &__btn {
    @include breakpoint(small) {
      padding: 0.7rem 1rem;
      font-size: 1.1rem;
    }
  }
}

// ********** FEATURED BRANDS ********** //
.featured-brands {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include breakpoint(medium up) {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
  &__header {
    font-size: 1.5rem;
    @include breakpoint(medium up) {
      font-size: 2.7rem;
    }
  }
  &__text {
    padding: 0 1rem;
    margin-bottom: 0;
    @include breakpoint(medium up) {
      padding: 0 5rem;
      margin-bottom: 4rem;
    }
  }
  &__bottom {
    margin-bottom: 4rem;
  }
}

.brand-links {
  margin-bottom: 3rem;
  &__link {
    margin-bottom: 1rem;
  }
}

// ********** NEWSLETTER ********** //
.newsletter {
  background-color: $primary-color;
  padding: 1rem 0.5rem;
  @include breakpoint(medium up) {
    padding: 2rem;
  }
  &--position {
    display: flex;
    align-items: center;
    justify-content: space-around;
    @include breakpoint(small only) {
      flex-direction: column;
    }
  }
  &__header {
    color: $white;
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 0.7rem;
    @include breakpoint(medium up) {
      font-size: 2rem;
      margin: 0;
    }
  }
}

.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  &__input {
    display: inline-block;
    margin: 0;
    &--font {
      font-family: FontAwesome, 'roboto';
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 500;
    }
  }
  &__btn {
    background-color: $black;
    margin: 0;
  }
}

// ********** GALLERY ********** //
.gallery {
  min-height: 400px;
  position: relative;
  @include breakpoint(large up) {
    min-height: 695px;
  }
  &::before {
    position: absolute;
    content: '';
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.gallery-bg {
  position: absolute;
  max-height: 695px;
  overflow: hidden;
  z-index: -1;
  width: 100%;
  img {
    width: 100%;
  }
}

.gallery-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  &__header {
    color: $white;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    @include breakpoint(large) {
      font-size: 3.2rem;
      margin-bottom: 0;
    }
  }
  &__description {
    color: $white;
    line-height: 1.2;
    @include breakpoint(medium up) {
      padding: 0 3rem;
    }
  }
  &__btn {
    font-size: 1.1rem;
  }
}