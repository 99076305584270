.wheel-info {
    padding: 0;
    &--blk {
        color: #000;
    }
    &__header{
        font-size: 1.5rem;
        font-weight:600;
        text-align: left;
        @include breakpoint(small only) {
            font-size: 1.5rem;
            margin: 0 0.5rem 1rem;
        }
    }
}