.footer {
  background-color: $black;
  &--margin {
    margin: 1rem 0;
    @include breakpoint(medium up) {
      margin: 5rem 0;
    }
  }
  &__header {
    color: $white;
    font-size: 1.2rem;
    &--spacing {
      padding-bottom: 1rem;
      margin: 0;
    }
    &--border {
      border-bottom: 1px solid $dark-gray;
    }
  }
  &__about {
    line-height: 1.3;
    padding-left: 1rem;
    padding-right: 1rem;
    @include breakpoint(large up) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__list {
    list-style: none;
    margin: 0;
  }
}

.footer-top {
  color: $medium-gray;
  &--padding {
    padding-top: 1rem;
    padding-bottom: 0;
    @include breakpoint(large up) {
      padding-bottom: 1rem;
    }
  }
  a {
    color: $medium-gray;
    &:hover {
      color: $primary-color;
    }
  }
}

.footer-social {
  @include breakpoint(small only) {
    margin-top: 1rem;
    text-align: center;
  }
  li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $dark-gray;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    margin: 0.25rem;
  }
}

.store-info {
  &__left,
  &__right {
    display: inline-block;
    width: 49%;
  }
  &__icon {
    color: $primary-color;
    margin-right: 0.5rem;
  }
  &__address {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
  }
}

.info-item {
  display: flex;
  &__text {
    line-height: 1.2;
    @include breakpoint(small only) {
      font-size: 0.9rem;
    }
  }
  .fa {
    font-size: 1.2rem;
  }
}

.support-links {
  li {
    text-transform: uppercase;
    padding: 0.7rem 0;
    border-bottom: 1px solid #555;
    a {
      font-size: 0.9rem;
    }
  }
}



// ********** COPY AND LINKS ********** //
.footer-bottom {
  background-color: $white;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  font-weight: 500;
  text-align: center;
  @include breakpoint(medium up) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  &__copy {
    font-size: 0.9rem;
    @include breakpoint(medium up) {
      font-size: 0.8rem;
    }
  }
  &__divider {
    padding: 0 0.25rem;
    display: inline-block;
    color: $medium-gray;
  }
  &__link {
    font-size: 0.9rem;
    color: $primary-color;
    @include breakpoint(medium up) {
      font-size: 0.8rem;
    }
  }
}