body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 900;
}

body {
    font-weight: 300;
}

h1,
h2,
h3 {
    color: #000000;
    text-transform: uppercase;
    line-height: 1;
}

h4,
h5,
h6 {
    font-weight: 700;
    letter-spacing: -1px;
}


.h1 {
    font-size: 1.5rem;
}

.h2 {
    font-size: 1.8rem;
    font-weight: 700;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
    color: #999;
}

p,
a {
    font-size: 1.1rem;
}

a {
    color: $black;
    &:hover {
        color: #666666;
    }
}
ul, ol {
    margin-right: 0;
    margin-bottom: 0;
}
table {
    min-width: 100%;
    margin-bottom: 0;
    border: 0;
}

table tr td,
table thead tr th {
    text-align: center;
}

table thead tr th {
    color: #fefefe;
    background: #707070;
}

.image-border {
    border: 1px solid #d1d1d1;
}

.bg-img {
    background-color: #333;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.hidden {
    display: none;
}

.product-paragraph {
    color: #000;
    margin-bottom: 0.5rem;
    text-align: left;
    margin-right: 1rem;
}

.info {
    &--limit {
        line-height: 1.2em;
        overflow: hidden;
        margin-bottom: 0;
    }

    &--height {
        height: 6em;
    }
}

.primary {
    color: $primary-color;
}
.primary-bg {
    background-color: $primary-color;
}

.primary-btn {
    border-radius: 4px;
    padding: 0.7rem 2rem;
    margin: 0;
    font-weight: 700;
    a {
        color: $white;
    }
}

.red-bg {
    background-color: $primary-color;
}

.black-font {
    color: #333;
}

.red {
    color: $primary-color;
}

.white {
    color: #fefefe;
}

.button {
    font-weight: bold;
    background-color: $primary-color;
    &--orange {
        background-color: #ff7a01;
    }
    &--gray {
        background-color: #aaa;
    }    
}

.no-margin {
    margin-bottom: 0;
}

.button.secondary {
    background-color: #e7e7e7;
    color: #323232;
}

.black,
.button.black {
    background-color: #000000;
}

.button,
.text-uppercase {
    text-transform: uppercase;
}

.button:hover {
    background-color: #000000;
}

.button-group .button {
    font-size: .8125rem;
    padding-right: 0;
    padding-left: 0;
    &:active,
    &:focus {
        background-color: $primary-color;
    }
}

.font-light {
    font-weight: 300;
}

.font-normal {
    font-weight: 400;
}

.font-bold {
    font-weight: 700;
}

.font-heavy {
    font-weight: 900;
}

.font-italic {
    font-style: italic;
}

.g-recaptcha {
    margin-bottom: 0.5rem;
    // display: inline-block;
}

#captchaCheck {
    float: left;
    margin-left: 1rem;
    text-transform: uppercase;
}

.padded {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.padded.extra {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.padded.short {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.padded-left {
    padding-left: .5rem;
}

.padded-right {
    padding-right: .5rem;
}

.container-padding--b {
    padding-bottom: 4rem;
}

.accordion .accordion-navigation>.content {
    padding-right: 0;
    padding-left: 0;
}

.accordion-item:first-child>:first-child {
    font-weight: 700;
}

.anchor {
    position: relative;
    top: -3rem;
    display: block;
    visibility: hidden;
}

.full-width {
    width: 100%;
    max-width: 100%;
}
.fixed {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
}
.title-bar,
.top-bar {
    padding: 0;
}
.top-bar,
.top-bar ul {
    background-color: transparent;
}
.top-bar ul.is-dropdown-submenu {
    background-color: rgba(0, 0, 0, 1);
    border: 0px;
}

.dropdown.menu .is-dropdown-submenu-parent.is-down-arrow>a::after {
    right: 0px;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a {
    padding-right: 1rem;
}

.dropdown.menu>li.is-dropdown-submenu-parent>a:after {
    border-color: #d40000 transparent transparent;
    right: 0px;
    display: none;
}

.dropdown.menu.vertical>li.opens-right>a::after {
    border-color: transparent transparent transparent #d40000;
    right: 5px;
}

.dropdown.menu>li>a:hover,
.dropdown.menu>li>ul li a:hover,
.dropdown.menu>li.is-active>a {
    color: #d40000;
}

.dropdown.menu>li>ul li a {
    padding: 1rem 2rem;
}

.dropdown.menu>li.opens-right>.is-dropdown-submenu {
    top: 154%;
    min-width: 280px;
    padding-bottom: 20px;
}

address {
    font-style: normal;
}
.pagination .current {
    background: $primary-color;
    font-size: 1rem;
    font-weight: 500;
}

/********* VIDEO *********/

#videos {
    margin-top: 3rem;
    a:hover {
        color: #666;
    }

    a::before {
        background: rgba(255, 255, 255, 0);
    }

    p {
        font-size: 1.6rem;
    }

    #next-button {
        background-color: #fff;
    }

    #video-container .column a {
        position: relative;
        display: block;
    }
}
.fa-youtube-play {
    display: block;
    color: #ff4747;
    position: absolute;
    top: 33%;
    width: 100%;
}



// ********** POPUP ********** //
.white-popup--wide {
    max-width: 700px;
}



// ********** PARALLAX ********** //
.parallax {
    position: relative;
    overflow: hidden;
    text-align: center;
    h1,
    h2 {
        position: relative;
        padding: 3.7rem;
    }
    h1 {
        letter-spacing: 0;
        img {
            padding-bottom: 1rem;
        }
    }
    .img {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #333;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .bg-top {
        background-position: center top;
    }
    .img:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        opacity: .5;
        background: #000;
    }
}



// ********** DETAIL PAGES ********** //
#detail-top p{
    font-size: 1rem;
}


// ********** HOVER ITEMS ********** //
.hover-item-link {
    display: block;
    padding: 1rem 0;
}

.hover-item {
    position: relative;
    display: block;
    -webkit-transition: border-color .3s ease-in-out;
    transition: border-color .3s ease-in-out;
    text-align: center;
    &:hover {
        .hover-item__img {
            opacity: 1;
            -webkit-transform: scale(1.1);
            -ms-transform: scale(1.1);
            transform: scale(1.1);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }
    }
    &__header {
        line-height: 1.2;
        margin-bottom: 0;
        font-size: 1.2rem;
    }
    &__header,
    h6 small {
        color: #545454;
    }
    &__img {
        -webkit-transition: -webkit-transform .3s ease-in-out;
        transition: transform .3s ease-in-out;
    }
}



@media screen and (max-width: 480px) {
    h1 {
        font-size: 2.7rem;
    }
    h2 {
        font-size: 2.1rem
    }
    h3 {
        font-size: 2rem;
    }
    h5 {
        font-size: 1.5rem;
    }
    p {
        font-size: 1rem;
    }
}

/* ==========================================================================
   10. Forms
   ========================================================================== */
/* honeypot */
.ohnohoney {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}