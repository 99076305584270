/* 
=======================================
   Table of Contents
=======================================
*/
/* 0, 640px */
/* 641px, 1024px */
/* 1025px, 1440px */
/* 1441px, 1920px */
/* 1921px, ... */
/**
 * Foundation for Sites by ZURB
 * Version 6.2.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed");
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
  background-color: black;
}

.t-uppercase {
  text-transform: uppercase;
}
.t-center {
  text-align: center;
}
@media screen and (max-width: 39.9375em) {
  .t-center--sm {
    text-align: center;
  }
}
.t-right {
  text-align: right;
}

.color--gray {
  color: #999999;
}

.margin--tb {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.padding--tb {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 900;
}

body {
  font-weight: 300;
}

h1,
h2,
h3 {
  color: #000000;
  text-transform: uppercase;
  line-height: 1;
}

h4,
h5,
h6 {
  font-weight: 700;
  letter-spacing: -1px;
}

.h1 {
  font-size: 1.5rem;
}

.h2 {
  font-size: 1.8rem;
  font-weight: 700;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #999;
}

p,
a {
  font-size: 1.1rem;
}

a {
  color: #0a0a0a;
}
a:hover {
  color: #666666;
}

ul, ol {
  margin-right: 0;
  margin-bottom: 0;
}

table {
  min-width: 100%;
  margin-bottom: 0;
  border: 0;
}

table tr td,
table thead tr th {
  text-align: center;
}

table thead tr th {
  color: #fefefe;
  background: #707070;
}

.image-border {
  border: 1px solid #d1d1d1;
}

.bg-img {
  background-color: #333;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.hidden {
  display: none;
}

.product-paragraph {
  color: #000;
  margin-bottom: 0.5rem;
  text-align: left;
  margin-right: 1rem;
}

.info--limit {
  line-height: 1.2em;
  overflow: hidden;
  margin-bottom: 0;
}
.info--height {
  height: 6em;
}

.primary {
  color: #ff0000;
}

.primary-bg {
  background-color: #ff0000;
}

.primary-btn {
  border-radius: 4px;
  padding: 0.7rem 2rem;
  margin: 0;
  font-weight: 700;
}
.primary-btn a {
  color: #fefefe;
}

.red-bg {
  background-color: #ff0000;
}

.black-font {
  color: #333;
}

.red {
  color: #ff0000;
}

.white {
  color: #fefefe;
}

.button {
  font-weight: bold;
  background-color: #ff0000;
}
.button--orange {
  background-color: #ff7a01;
}
.button--gray {
  background-color: #aaa;
}

.no-margin {
  margin-bottom: 0;
}

.button.secondary {
  background-color: #e7e7e7;
  color: #323232;
}

.black,
.button.black {
  background-color: #000000;
}

.button,
.text-uppercase {
  text-transform: uppercase;
}

.button:hover {
  background-color: #000000;
}

.button-group .button {
  font-size: 0.8125rem;
  padding-right: 0;
  padding-left: 0;
}
.button-group .button:active, .button-group .button:focus {
  background-color: #ff0000;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.font-heavy {
  font-weight: 900;
}

.font-italic {
  font-style: italic;
}

.g-recaptcha {
  margin-bottom: 0.5rem;
}

#captchaCheck {
  float: left;
  margin-left: 1rem;
  text-transform: uppercase;
}

.padded {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.padded.extra {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.padded.short {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.padded-left {
  padding-left: 0.5rem;
}

.padded-right {
  padding-right: 0.5rem;
}

.container-padding--b {
  padding-bottom: 4rem;
}

.accordion .accordion-navigation > .content {
  padding-right: 0;
  padding-left: 0;
}

.accordion-item:first-child > :first-child {
  font-weight: 700;
}

.anchor {
  position: relative;
  top: -3rem;
  display: block;
  visibility: hidden;
}

.full-width {
  width: 100%;
  max-width: 100%;
}

.fixed {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
}

.title-bar,
.top-bar {
  padding: 0;
}

.top-bar,
.top-bar ul {
  background-color: transparent;
}

.top-bar ul.is-dropdown-submenu {
  background-color: rgb(0, 0, 0);
  border: 0px;
}

.dropdown.menu .is-dropdown-submenu-parent.is-down-arrow > a::after {
  right: 0px;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  padding-right: 1rem;
}

.dropdown.menu > li.is-dropdown-submenu-parent > a:after {
  border-color: #d40000 transparent transparent;
  right: 0px;
  display: none;
}

.dropdown.menu.vertical > li.opens-right > a::after {
  border-color: transparent transparent transparent #d40000;
  right: 5px;
}

.dropdown.menu > li > a:hover,
.dropdown.menu > li > ul li a:hover,
.dropdown.menu > li.is-active > a {
  color: #d40000;
}

.dropdown.menu > li > ul li a {
  padding: 1rem 2rem;
}

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 154%;
  min-width: 280px;
  padding-bottom: 20px;
}

address {
  font-style: normal;
}

.pagination .current {
  background: #ff0000;
  font-size: 1rem;
  font-weight: 500;
}

/********* VIDEO *********/
#videos {
  margin-top: 3rem;
}
#videos a:hover {
  color: #666;
}
#videos a::before {
  background: rgba(255, 255, 255, 0);
}
#videos p {
  font-size: 1.6rem;
}
#videos #next-button {
  background-color: #fff;
}
#videos #video-container .column a {
  position: relative;
  display: block;
}

.fa-youtube-play {
  display: block;
  color: #ff4747;
  position: absolute;
  top: 33%;
  width: 100%;
}

.white-popup--wide {
  max-width: 700px;
}

.parallax {
  position: relative;
  overflow: hidden;
  text-align: center;
}
.parallax h1,
.parallax h2 {
  position: relative;
  padding: 3.7rem;
}
.parallax h1 {
  letter-spacing: 0;
}
.parallax h1 img {
  padding-bottom: 1rem;
}
.parallax .img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #333;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.parallax .bg-top {
  background-position: center top;
}
.parallax .img:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  opacity: 0.5;
  background: #000;
}

#detail-top p {
  font-size: 1rem;
}

.hover-item-link {
  display: block;
  padding: 1rem 0;
}

.hover-item {
  position: relative;
  display: block;
  -webkit-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  text-align: center;
}
.hover-item:hover .hover-item__img {
  opacity: 1;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.hover-item__header {
  line-height: 1.2;
  margin-bottom: 0;
  font-size: 1.2rem;
}
.hover-item__header,
.hover-item h6 small {
  color: #545454;
}
.hover-item__img {
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 2.7rem;
  }
  h2 {
    font-size: 2.1rem;
  }
  h3 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.5rem;
  }
  p {
    font-size: 1rem;
  }
}
/* ==========================================================================
   10. Forms
   ========================================================================== */
/* honeypot */
.ohnohoney {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.header {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 40em) {
  .header--home {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}
.header__top {
  background-color: rgb(0, 0, 0);
}
@media screen and (min-width: 64em) {
  .header__top--home {
    background-color: rgb(0, 0, 0);
  }
}
.header__bot {
  background-color: #fefefe;
}
.header .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
  padding-right: 1rem;
}
.header .dropdown.menu.vertical > li.opens-right > a:after {
  border: 0;
}
.header .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
  margin: 0;
  list-style: none;
}
.header .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu a:hover {
  border-bottom: 0;
}

.is-drilldown-submenu.is-active {
  margin: 0;
}

.is-drilldown-submenu-parent > a:after {
  border-left-color: #ee383a;
}

.js-drilldown-back > a:before {
  border-right-color: #ee383a;
}

.is-drilldown-submenu.is-active a, .is-submenu-item.is-drilldown-submenu-item a {
  display: block;
}

.top-bar a {
  font-size: 1rem;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}

.logo {
  text-align: center;
}
@media screen and (min-width: 64em) {
  .logo {
    width: auto;
    text-align: left;
  }
  .logo--padding {
    min-width: 140px;
    height: 5px;
  }
  .logo__link {
    position: absolute;
  }
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.center-container__block {
  line-height: 1.2;
}
.center-container__header {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1;
  margin: 0;
}
.center-container__link {
  font-size: 0.9rem;
}

.header-statement {
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1.3;
  color: #fff;
  margin: 0.5rem 0;
}
@media screen and (min-width: 40em) {
  .header-statement {
    font-size: 0.9rem;
    line-height: 1.5;
  }
}

.header-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0;
}
@media screen and (min-width: 64em) {
  .header-top-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.header-top-left {
  display: flex;
  flex-direction: column;
}

.phone-number {
  display: inline-block;
}
.phone-number__icon {
  color: #ff0000;
  border: 3px solid red;
  border-radius: 25%;
  height: 1.7rem;
  width: 1.7rem;
  font-size: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 40em) {
  .phone-number__icon {
    height: 2.2rem;
    width: 2.2rem;
    font-size: 1.5rem;
  }
}
.phone-number__text {
  color: #fefefe;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1;
}
@media (max-width: 320px) {
  .phone-number__text {
    font-size: 1rem;
  }
}
@media screen and (min-width: 40em) {
  .phone-number__text {
    font-size: 2.2rem;
    vertical-align: sub;
  }
}

.header-top-right {
  display: flex;
  justify-content: space-between;
}

.nav-btn {
  font-size: 2rem;
  margin: 0 1.5rem;
}
@media screen and (min-width: 64em) {
  .nav-btn {
    display: none;
  }
}

.account-links {
  background-color: #666666;
  border-radius: 50%;
  height: 2.1rem;
  width: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.2rem;
}
@media (max-width: 320px) {
  .account-links {
    height: 1.7rem;
    width: 1.7rem;
  }
}

.nav {
  background-color: #fefefe;
}
.nav--padding {
  padding: 0;
}
@media screen and (min-width: 64em) {
  .nav--padding {
    padding-left: 156px;
  }
}
.nav--gray {
  background-color: #cccccc;
}
@media screen and (min-width: 64em) {
  .nav--gray {
    background-color: #fefefe;
  }
}
@media screen and (min-width: 64em) {
  .nav--hidden {
    display: none !important;
  }
}

.menu li a {
  border-bottom: 1px solid #bbb;
  padding: 0.7rem 1.5rem;
  color: #0a0a0a;
  font-size: 1rem;
}
@media screen and (min-width: 40em) {
  .menu li a {
    width: 100%;
  }
}
@media screen and (min-width: 64em) {
  .menu li a {
    border-bottom: none;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0.7rem 0.5rem;
  }
  .menu li a:hover .center-icon {
    border-color: #ff0000;
  }
}
.menu li a:hover:not(.search-link) {
  border-bottom: 4px solid #ff0000;
}

.search-item {
  position: relative;
}
@media screen and (min-width: 64em) {
  .search-item:after {
    content: "";
    height: 60%;
    width: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translatey(-50%);
    background-color: #cccccc;
  }
}
.search-item__text {
  font-size: 0.9rem;
  font-weight: initial;
}

.search-form {
  display: none;
  position: relative;
  left: 0;
  width: 100%;
}
@media screen and (min-width: 64em) {
  .search-form {
    position: absolute;
    width: 423px;
    left: -254px;
  }
}
.search-form__select {
  margin: 0;
}
.search-form__btn {
  max-height: 2.5rem;
}

.center-icon {
  display: inline-flex;
}
@media screen and (min-width: 40em) {
  .center-icon {
    border-radius: 50%;
    height: 2.1rem;
    width: 2.1rem;
    align-items: center;
    justify-content: center;
    border: 2px solid #999999;
    font-size: 0.9rem;
  }
  .center-icon__icon {
    margin: 0 !important;
  }
}

/* Media Queries */
@media screen and (max-width: 1023px) {
  header {
    padding: 0;
  }
  header .top-bar .top-bar-left,
  header .top-bar .top-bar-right {
    border-right: 0px;
    width: 100%;
  }
  header .top-bar .top-bar-right {
    padding: 10px 20px 20px;
  }
  header .top-bar .top-bar-left li {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #eeeeee;
  }
  header .top-bar .top-bar-left ul ul div div {
    border-right: 0;
    margin: 0;
  }
  header .top-bar .top-bar-left ul ul div div:last-child {
    border: none;
  }
  header .top-bar .top-bar-left .dropdown.menu .submenu li {
    padding-left: 0;
    padding-right: 0;
    border-bottom: 0;
    border-top: 1px solid #eeeeee;
  }
  .menu-icon::after {
    background-color: #999999;
    box-shadow: 0 7px 0 #999999, 0 14px 0 #999999;
  }
  .menu > li {
    display: inherit;
  }
  .dropdown.menu .submenu {
    position: relative;
    border: 0;
  }
}
.footer {
  background-color: #0a0a0a;
}
.footer--margin {
  margin: 1rem 0;
}
@media screen and (min-width: 40em) {
  .footer--margin {
    margin: 5rem 0;
  }
}
.footer__header {
  color: #fefefe;
  font-size: 1.2rem;
}
.footer__header--spacing {
  padding-bottom: 1rem;
  margin: 0;
}
.footer__header--border {
  border-bottom: 1px solid #666666;
}
.footer__about {
  line-height: 1.3;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media screen and (min-width: 64em) {
  .footer__about {
    padding-left: 0;
    padding-right: 0;
  }
}
.footer__list {
  list-style: none;
  margin: 0;
}

.footer-top {
  color: #999999;
}
.footer-top--padding {
  padding-top: 1rem;
  padding-bottom: 0;
}
@media screen and (min-width: 64em) {
  .footer-top--padding {
    padding-bottom: 1rem;
  }
}
.footer-top a {
  color: #999999;
}
.footer-top a:hover {
  color: #ff0000;
}

@media screen and (max-width: 39.9375em) {
  .footer-social {
    margin-top: 1rem;
    text-align: center;
  }
}
.footer-social li {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #666666;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  margin: 0.25rem;
}

.store-info__left, .store-info__right {
  display: inline-block;
  width: 49%;
}
.store-info__icon {
  color: #ff0000;
  margin-right: 0.5rem;
}
.store-info__address {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.info-item {
  display: flex;
}
.info-item__text {
  line-height: 1.2;
}
@media screen and (max-width: 39.9375em) {
  .info-item__text {
    font-size: 0.9rem;
  }
}
.info-item .fa {
  font-size: 1.2rem;
}

.support-links li {
  text-transform: uppercase;
  padding: 0.7rem 0;
  border-bottom: 1px solid #555;
}
.support-links li a {
  font-size: 0.9rem;
}

.footer-bottom {
  background-color: #fefefe;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  font-weight: 500;
  text-align: center;
}
@media screen and (min-width: 40em) {
  .footer-bottom {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
.footer-bottom__copy {
  font-size: 0.9rem;
}
@media screen and (min-width: 40em) {
  .footer-bottom__copy {
    font-size: 0.8rem;
  }
}
.footer-bottom__divider {
  padding: 0 0.25rem;
  display: inline-block;
  color: #999999;
}
.footer-bottom__link {
  font-size: 0.9rem;
  color: #ff0000;
}
@media screen and (min-width: 40em) {
  .footer-bottom__link {
    font-size: 0.8rem;
  }
}

.vehicle-select {
  background-color: #fefefe;
}
.vehicle-select__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
}
@media screen and (min-width: 64em) {
  .vehicle-select__top {
    padding: 1rem 0;
    border-bottom: 1px solid #cccccc;
  }
}
.vehicle-select__header {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
}
.vehicle-select__icon {
  color: #ff0000;
}
.vehicle-select__links {
  margin-bottom: 1rem;
}
.vehicle-select__links a {
  color: #ff0000;
  font-size: 0.9rem;
  font-weight: 500;
  font-style: italic;
  text-transform: uppercase;
  text-decoration: underline;
}
@media screen and (min-width: 64em) {
  .vehicle-select__links a {
    font-size: 1rem;
  }
}
.vehicle-select__bottom {
  padding-top: 1rem;
  font-weight: 700;
}
@media screen and (min-width: 64em) {
  .vehicle-select__bottom {
    padding: 1rem 0;
  }
}
.vehicle-select .select {
  border-radius: 3px;
  border-width: 2px;
}
.vehicle-select .select:disabled {
  background-color: #cccccc;
  color: #999999;
}

.acmia-banner-link {
  display: block;
}

.acmia-banner-img {
  width: 100%;
}

.cta-top {
  background-color: #ff0000;
}
.cta-top__header {
  color: #fefefe;
  font-size: 1.2rem;
  line-height: 1.2;
  margin: 0;
  padding: 0.5rem 1.5rem;
}
@media screen and (min-width: 64em) {
  .cta-top__header {
    font-size: 1.5rem;
    padding: 1rem 0;
  }
}

.cta-bottom {
  position: relative;
}
.cta-bottom__links {
  position: absolute;
  top: 50%;
  bottom: 0;
  transform: translateY(-50%);
  width: 100%;
  display: block;
}
@media screen and (min-width: 64em) {
  .cta-bottom__links {
    top: 0;
    transform: none;
  }
}
.cta-bottom__img {
  width: 100%;
}
.cta-bottom__column {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
@media screen and (min-width: 64em) {
  .cta-bottom__column {
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
  }
}

.cta-link {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
@media screen and (min-width: 40em) {
  .cta-link--left {
    clip-path: polygon(0 0, 100% 0%, 84% 99%, 0 100%);
    -webkit-clip-path: polygon(0 0, 100% 0%, 84% 99%, 0 100%);
  }
  .cta-link--mid {
    clip-path: polygon(0 0, 90% 0%, 100% 100%, 5.5% 100%);
    -webkit-clip-path: polygon(0 0, 90% 0%, 100% 100%, 5.5% 100%);
  }
  .cta-link--right {
    clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0 100%);
    -webkit-clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0 100%);
  }
}
.cta-link__btn {
  padding: 0.7rem 1rem;
  font-size: 1.1rem;
}

.featured-brands {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
@media screen and (min-width: 40em) {
  .featured-brands {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }
}
.featured-brands__header {
  font-size: 1.5rem;
}
@media screen and (min-width: 40em) {
  .featured-brands__header {
    font-size: 2.7rem;
  }
}
.featured-brands__text {
  padding: 0 1rem;
  margin-bottom: 0;
}
@media screen and (min-width: 40em) {
  .featured-brands__text {
    padding: 0 5rem;
    margin-bottom: 4rem;
  }
}
.featured-brands__bottom {
  margin-bottom: 4rem;
}

.brand-links {
  margin-bottom: 3rem;
}
.brand-links__link {
  margin-bottom: 1rem;
}

.newsletter {
  background-color: #ff0000;
  padding: 1rem 0.5rem;
}
@media screen and (min-width: 40em) {
  .newsletter {
    padding: 2rem;
  }
}
.newsletter--position {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
@media screen and (max-width: 39.9375em) {
  .newsletter--position {
    flex-direction: column;
  }
}
.newsletter__header {
  color: #fefefe;
  font-size: 1.1rem;
  text-align: center;
  margin-bottom: 0.7rem;
}
@media screen and (min-width: 40em) {
  .newsletter__header {
    font-size: 2rem;
    margin: 0;
  }
}

.signup {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup__input {
  display: inline-block;
  margin: 0;
}
.signup__input--font {
  font-family: FontAwesome, "roboto";
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
}
.signup__btn {
  background-color: #0a0a0a;
  margin: 0;
}

.gallery {
  min-height: 400px;
  position: relative;
}
@media screen and (min-width: 64em) {
  .gallery {
    min-height: 695px;
  }
}
.gallery::before {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.gallery-bg {
  position: absolute;
  max-height: 695px;
  overflow: hidden;
  z-index: -1;
  width: 100%;
}
.gallery-bg img {
  width: 100%;
}

.gallery-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.gallery-content__header {
  color: #fefefe;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
@media screen and (min-width: 64em) {
  .gallery-content__header {
    font-size: 3.2rem;
    margin-bottom: 0;
  }
}
.gallery-content__description {
  color: #fefefe;
  line-height: 1.2;
}
@media screen and (min-width: 40em) {
  .gallery-content__description {
    padding: 0 3rem;
  }
}
.gallery-content__btn {
  font-size: 1.1rem;
}

.home-slider-container {
  margin-bottom: 0;
}
.home-slider-container .slick-slide {
  position: relative;
}

.home-slider-img {
  width: 100%;
}

.hero {
  position: relative;
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hero {
    margin-top: 6rem;
  }
}
@media screen and (min-width: 64em) {
  .hero {
    margin-top: 8rem;
  }
}

.hero-bkg {
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (min-width: 40em) {
  .hero-bkg {
    background-size: cover;
  }
}

.hero-overlay {
  padding: 0.5rem;
  background-color: #000;
}
.hero-overlay-content {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1023px) {
  .hero-overlay-content {
    align-items: center;
    text-align: center;
  }
}
@media screen and (min-width: 64em) {
  .hero-overlay {
    position: absolute;
    top: 15%;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: transparent;
  }
}
.hero-overlay-info {
  display: inline-block;
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  padding: 0.5rem;
  color: #fefefe;
  margin-bottom: 1.5rem;
  max-width: 500px;
  width: 100%;
}
@media screen and (min-width: 40em) {
  .hero-overlay-info {
    margin: 0 0 1rem;
    padding: 1rem;
  }
}
@media screen and (min-width: 64em) {
  .hero-overlay-info {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
.hero-overlay-info--small {
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
}
@media screen and (min-width: 40em) {
  .hero-overlay-info--small {
    font-size: 1.43rem;
  }
}
.hero-overlay-info--medium {
  font-size: 1.7rem;
  font-style: italic;
}
@media screen and (min-width: 40em) {
  .hero-overlay-info--medium {
    font-size: 2.4rem;
  }
}
.hero-overlay-info--large {
  color: #fefefe;
  font-size: 2rem;
  font-style: italic;
  margin: 0;
}
@media screen and (min-width: 40em) {
  .hero-overlay-info--large {
    font-size: 4.6rem;
  }
}
.hero-overlay-header {
  font-style: italic;
  font-size: 2rem;
}
@media screen and (min-width: 64em) {
  .hero-overlay-header {
    font-size: 3.5rem;
  }
}
.hero-overlay-desc {
  margin: 0;
  font-weight: 400;
  line-height: 1.2;
}

.hero-btn {
  align-self: flex-start;
}
@media screen and (min-width: 40em) {
  .hero-btn {
    display: inline-block;
  }
}
@media (max-width: 1023px) {
  .hero-btn {
    margin: 0 auto 1rem;
  }
}

#wheel .wheel-sizes {
  margin-top: 1rem;
}
#wheel .wheel-sizes span {
  font-size: 1rem;
  font-weight: bold;
}
#wheel .wheel-details {
  background: #f1f1f1;
  padding: 1rem 1.5rem;
}
#wheel .wheel-details .wheel-paragraph {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 1rem;
}
#wheel .wheel-details .wheel-paragraph span {
  font-size: 1.2rem;
}
#wheel .loadingImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}

.gallery-popup {
  display: table;
  margin: 0 auto;
}
.gallery-popup .galleryView-info {
  color: #ccc;
  font-size: 1.1rem;
  font-weight: 600;
  margin-top: 1rem;
}
.gallery-popup .popup-car {
  font-weight: 900;
  font-size: 1.3rem;
  color: #fff;
}
.gallery-popup .mfp-close {
  color: white !important;
  position: relative;
  float: right;
}

@media screen and (min-width: 40em) {
  .accordion-content {
    padding: 0;
    border: 0;
    border-bottom: 0 !important;
  }
  .alt-images .slick-slide {
    width: 100% !important;
  }
}
.detail-thumb p {
  line-height: 1;
  margin: 0.25rem;
}
.detail-thumb .finish-name {
  color: #666666;
  font-size: 0.8rem;
}
.detail-thumb img {
  transition: transform 0.3s ease-in-out;
}
.detail-thumb img:hover {
  backface-visibility: hidden;
  transform: scale(1.08);
}

#specs {
  margin-bottom: 1.25rem;
  overflow: auto;
}

table {
  border: 0;
  margin-bottom: 0;
}
table tr td,
table tr th {
  text-align: center;
}
table tr th {
  background: #cccccc;
}

@media screen and (max-width: 39.9375em) {
  #wheel .banner-top .wheel-banner {
    height: 6rem;
  }
  #wheel .banner-top .heading {
    font-size: 1.7rem;
    top: 90%;
  }
  #wheel .slick-slide {
    display: inline-block;
    float: none;
  }
}
@media screen and (min-width: 40em) {
  .finish-header {
    display: none;
  }
}
.fitmentCheck_text {
  color: #ff7a01;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.fitmentCheck_Button {
  background-color: #ff7a01;
  padding-right: 2rem;
  padding-left: 2rem;
}

.selectedVehicleBar {
  position: relative;
  background-color: #ebebeb;
}
.selectedVehicleBar img {
  max-height: 100px;
}
.selectedVehicleBar .row--small {
  max-width: 67rem;
}
.selectedVehicleBar__content {
  margin: 1.5rem auto 0;
  padding-left: 0;
  padding-right: 0;
}
.selectedVehicleBar__content--noMargin {
  margin: 0;
}
.selectedVehicleBar__text {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0;
}
.selectedVehicleBar__span {
  color: #6a6a6a;
}
.selectedVehicleBar__btn {
  width: 100%;
  margin-bottom: 0;
}
.selectedVehicleBar__btn--smaller {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
}

.wheel-filter {
  text-align: center;
}
@media screen and (min-width: 40em) {
  .wheel-filter {
    display: flex;
    align-items: center;
  }
}
.wheel-filter__header {
  font-size: 1rem;
  display: inline-block;
  margin: 0;
}
.wheel-filter__dropdown {
  display: inline-block;
  margin: 0 0.5rem;
}
@media screen and (min-width: 40em) {
  .wheel-filter__dropdown select {
    margin: 0;
  }
}
.wheel-filter__btn {
  margin: 0;
}

.filters__header {
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem;
  padding: 30px 0;
  text-transform: uppercase;
}
.filters__select {
  background: #3f4851;
  padding: 1.5rem 0.8rem 0.8rem;
}
.filters__select select {
  text-transform: uppercase;
  background-color: #e1e1e1;
  font-weight: 500;
}
.filters__button {
  background-color: #e1e1e1;
  color: #000;
  font-weight: 500;
  max-height: 2.45rem;
  width: 100%;
}

.main-image {
  margin-bottom: 0;
}
.main-image img {
  margin: 0 auto;
}

.alt-images .slick-track {
  margin: 0 auto;
}
@media screen and (min-width: 40em) {
  .alt-images--retailer .slick-track {
    width: 100% !important;
    margin-top: 3rem;
  }
}
.alt-images .slick-dots {
  position: relative !important;
  text-align: center;
  bottom: 0;
  margin: 0;
}
.alt-images .slick-slide:focus {
  outline: 0;
}
.alt-images a {
  margin-right: 0;
  padding: 0.7rem;
}
.alt-images img {
  transition: transform 0.3s ease-in-out;
}
.alt-images img:hover {
  backface-visibility: hidden;
  transform: scale(1.08);
}

.wheel-specs {
  margin-top: 2rem;
}
.wheel-specs__header {
  font-size: 1.5rem;
  margin-bottom: 0;
}
.wheel-specs__details {
  margin-left: 0;
  list-style: none;
  font-weight: 600;
}

.wheel-info {
  padding: 0;
}
.wheel-info--blk {
  color: #000;
}
.wheel-info__header {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left;
}
@media screen and (max-width: 39.9375em) {
  .wheel-info__header {
    font-size: 1.5rem;
    margin: 0 0.5rem 1rem;
  }
}

.cta-buttons {
  margin: 0;
  list-style: none;
}
.cta-buttons__btn {
  width: 100%;
}
.cta-buttons__btn--space {
  margin-bottom: 1rem !important;
}

.details-media-container {
  position: relative;
  max-width: 500px;
  margin: 0 auto;
}

.details-media {
  z-index: 5;
  text-align: center;
}
.details-media__header {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  letter-spacing: 0;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: color 0.3s ease-out;
  -moz-transition: color 0.3s ease-out;
  -o-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
  color: #000;
}
.details-media__header:hover {
  color: #ff0000;
}

.details-divider {
  padding-bottom: 20px;
}
.details-divider:before {
  background-color: #c7c7c9;
  content: "";
  display: block;
  height: 1px;
  margin: 15px auto 0;
  width: 2rem;
}

@media screen and (max-width: 39.9375em) {
  .details-media-container {
    margin-bottom: 1rem;
  }
  .details-360,
  .details-video {
    display: inline-block;
    margin: 0 1rem;
  }
  .details-divider {
    display: none;
  }
}
#see-on-vehicle-detail h5 {
  font-size: 1.5rem;
}
#see-on-vehicle-detail select {
  font-size: 0.875rem;
}
#see-on-vehicle-detail .error {
  display: none;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
#see-on-vehicle-detail .no-fitment {
  min-height: 6rem;
  position: relative;
}
#see-on-vehicle-detail .no-fitment::before {
  background: #fefefe;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0.8;
  position: absolute;
  z-index: 1;
}
#see-on-vehicle-detail .no-fitment .error {
  display: block;
}
#see-on-vehicle-detail .loadingImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}
#see-on-vehicle-detail .hover-item:hover button {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
#see-on-vehicle-detail .hover-item:hover img {
  opacity: 0.075;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}
#see-on-vehicle-detail .hover-item:hover::before {
  opacity: 0.7;
}
#see-on-vehicle-detail button {
  position: absolute;
  top: 40%;
  left: 0;
  padding: 1rem;
  width: 100%;
  font-size: 0.8rem;
  opacity: 0;
}
#see-on-vehicle-detail button:hover {
  background-color: #666;
}
#see-on-vehicle-detail-overlay {
  width: 100%;
  height: 100%;
  display: table;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  color: #fff;
  font-size: 50px;
}
#see-on-vehicle-detail-overlay .fa-spinner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.wheel-gallery__header {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.105;
}
@media screen and (max-width: 39.9375em) {
  .wheel-gallery__header {
    margin-top: 1rem;
  }
}

.wheel-galleryList {
  margin: 0;
}
@media screen and (max-width: 39.9375em) {
  .wheel-galleryList {
    margin-left: 0.5rem;
  }
}
.wheel-galleryList__item {
  list-style: none;
  float: left;
  width: 31%;
  height: auto;
  margin-right: 2%;
  margin-top: 0.5rem;
}
.wheel-galleryList__item:nth-child(n+7) {
  display: none;
}

.wheel-galleryImage {
  width: 190px;
  height: 102px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
@media screen and (max-width: 39.9375em) {
  .wheel-galleryImage {
    width: 95px;
    height: 60px;
  }
}

@media screen and (max-width: 39.9375em) {
  .wheel-galleryTxt {
    font-size: 0.8rem;
  }
}
.wheel-spec-header {
  background-color: #ff0000;
  margin-bottom: 0;
  font-weight: 600;
}
.wheel-spec-tabs > a {
  font-size: 1.2rem;
  color: #000;
}
.wheel-spec-tabs > a:focus, .wheel-spec-tabs > a[aria-selected=true] {
  color: #fff;
  background-color: #999;
}
.wheel-spec-tabs > a:hover {
  color: #000;
}
.wheel-spec-panel {
  padding: 0;
}
.wheel-spec-btn {
  color: #c00;
}
.wheel-spec-btn:hover {
  color: #999;
}

.tire-details {
  background: #f1f1f1;
  padding: 1rem 1.5rem;
}

.tire-info__header {
  font-size: 1.5rem;
}

.tire-cta .button-group {
  margin: 0;
}
.tire-cta .button-group li {
  margin-top: 1rem;
}
.tire-cta .button-group .button {
  width: 100%;
}

.accessory-image {
  max-height: 600px;
}

.accessory-header {
  padding-top: 50px;
  font-weight: 700;
  font-size: 1.5rem;
}

#gallery-header {
  margin-bottom: 2rem;
}
#gallery-header .accordion-title {
  font-size: 1.1rem;
  color: #ff0000;
}

#gallery .hover-list .photo {
  position: relative;
  display: block;
  padding: 0 1px 1px 0;
}
#gallery .hover-list .photo:before {
  position: absolute;
  z-index: 1;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: all;
  transition-property: all;
  background: rgba(255, 255, 255, 0);
}
#gallery .hover-list .photo:after {
  font-size: 1.1rem;
  position: absolute;
  z-index: 2;
  top: 50%;
  display: block;
  width: 90%;
  margin: -1em 5% 0;
  padding-bottom: 2em;
  content: attr(title);
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: all;
  transition-property: all;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 0;
  color: #d40000;
  filter: alpha(opacity=0);
}
#gallery .hover-list .photo:hover:before {
  background: rgba(255, 255, 255, 0.9);
}
#gallery .hover-list .photo:hover:after {
  opacity: 1;
}
#gallery .hover-list .video:after {
  font-weight: 300;
  color: #fefefe;
}
#gallery .hover-list .video:hover:before {
  background: rgba(223, 61, 61, 0.9);
}

.gallery-title {
  font-size: 2rem;
  margin: 0;
}

#gallery-main-image {
  margin-bottom: 1rem;
}
#gallery-main-image img {
  width: 100%;
}

.gallery-thumb {
  margin-bottom: 1rem;
}

#gallery-details {
  margin-bottom: 2rem;
}

#gallery-thumbs .slick-dots {
  bottom: 0 !important;
  position: relative;
  margin: 0;
}

.gallery-popup-quote__header {
  font-size: 1.9rem;
}
.gallery-popup-quote__txt {
  font-size: 0.95rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 39.9375em) {
  .gallery-popup-quote__txt {
    line-height: 1.2;
  }
}
.gallery-popup-quote__note {
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 0;
}
.gallery-popup-quote label {
  font-size: 1rem;
  font-weight: 700;
}
.gallery-popup-quote input:not([type=submit]) {
  background-color: #eee;
  box-shadow: none;
  border: none;
  color: #000;
}

.form-name {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 39.9375em) {
  .form-name {
    flex-direction: column;
  }
}
.form-name input {
  width: 19.5rem;
}
@media screen and (max-width: 39.9375em) {
  .form-name input {
    width: 100%;
  }
}
.form-name input::placeholder {
  color: #333;
  font-size: 0.9rem;
}

.gallery-product-info span {
  font-weight: 400;
}

#contact p,
#thanks p,
#contact li,
#thanks li,
#gallery p,
#gallery li {
  color: #999999;
  font-weight: 400;
  padding-top: 0px;
}

#contact h3,
#thanks h3,
#gallery h3 {
  font-size: 2rem;
  margin-bottom: 0px;
}

#contact p.red {
  color: #d40000;
  font-size: 1rem;
  font-weight: 600;
}

#form-right li {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
#form-right .fa {
  font-size: 2.25rem;
}
#form-right h3 {
  font-size: 1.6rem;
  letter-spacing: -1px;
}
#form-right address {
  margin-bottom: 20px;
}

.page {
  padding-bottom: 50px;
}
.page__content {
  padding-top: 50px;
}
.page__content h1, .page__content h2 {
  text-transform: initial;
  font-family: "Roboto Condensed";
}

.page-header {
  font-size: 2.5rem;
  font-weight: 700;
  font-family: "Roboto Condensed";
}

.news-article {
  position: relative;
  overflow: auto;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ccc;
}
.news-article .timestamp {
  font-size: 0.8rem;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem;
  text-align: center;
  color: #fefefe;
  background: #333;
}
.news-article .timestamp .date {
  font-size: 2.75rem;
  font-weight: 900;
  line-height: 1;
  display: block;
}
.news-article img {
  border-bottom: 5px solid #d40000;
}
.news-article .news-sidebar {
  padding: 1rem;
  background: #fbfbfb;
}
.news-article .news-sidebar li a {
  color: #505050;
}
.news-article .news-sidebar li a:hover {
  color: #d40000;
}

#error-page .page-content {
  background-image: url("/img/utility/error-bg (2).png");
  background-position: center 5%;
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: center 5px;
  padding: 75px;
  margin-top: 75px;
  position: relative;
}
#error-page .page-content h3 {
  font-size: 3rem;
  font-weight: 1000;
  color: black;
  margin-top: 2rem;
}
#error-page .page-content a, #error-page .page-content .button {
  transition: all 0.3s ease-in-out;
}
#error-page .page-content p {
  font-weight: 400;
  color: #0a0a0a;
  font-size: 1.1rem;
  margin-bottom: 3rem;
}
#error-page .page-content p a {
  color: red;
  font-weight: 600;
}
#error-page .page-content p a:hover {
  color: #666666;
}
#error-page .page-content ul {
  list-style: none;
  margin-left: 0;
  margin-bottom: 2rem;
}
#error-page .page-content .button {
  max-width: 260px;
  width: 100%;
}
#error-page .page-content .button:hover {
  color: #666666;
  color: #cccccc;
}

.packaging {
  background-color: #F5F5F5;
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  position: relative;
  width: auto;
}
.packaging .popup_header {
  font-size: 1.2rem;
}
.packaging .button-group {
  margin-bottom: 0;
  overflow: auto;
}
.packaging .returnTxt {
  font-size: 1.6rem;
  margin-top: 1rem;
}

.accessory-container {
  margin-bottom: 1rem;
}
@media screen and (min-width: 40em) {
  .accessory-container {
    border-bottom: 1px solid #ccc;
  }
}
.accessory-container__left {
  border-right: 1px solid #ccc;
}

.accessory-popup {
  max-width: 425px;
  padding: 0 0 10px;
  text-align: center;
}
.accessory-popup--wide {
  max-width: 850px;
}
.accessory-popup__header {
  padding-top: 10px;
  border-bottom: 3px solid #ccc;
  background-color: #f2f2f2;
  color: #ff0000;
  font-size: 1.5rem;
  font-weight: bold;
}
.accessory-popup__item {
  width: 90%;
  margin: 0 auto 10px;
  padding-bottom: 15px;
  text-align: left;
}
.accessory-popup__item--border:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
@media screen and (max-width: 39.9375em) {
  .accessory-popup__item {
    border-bottom: 1px solid #ccc;
  }
}
.accessory-popup__item .input {
  margin: 0 20px;
}
.accessory-popup__item .input--right {
  margin: 0;
}
.accessory-popup__item .img {
  max-width: 100px;
}
@media screen and (max-width: 39.9375em) {
  .accessory-popup__item .img {
    max-width: 90px;
  }
}
.accessory-popup__item .img--lrg {
  max-width: 50%;
  margin-bottom: 1rem;
}
@media screen and (max-width: 39.9375em) {
  .accessory-popup .title {
    font-size: 0.8rem;
  }
}
.accessory-popup .title--right {
  display: block;
  text-align: center;
  line-height: 1.2;
}
.accessory-popup__link {
  display: inline-block;
  color: #ff0000;
  cursor: pointer;
  font-size: 100%;
}
.accessory-popup__link:hover {
  text-decoration: underline;
}

.accessory-popup--right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.accessory-popup--right .input {
  margin: 0;
}
.accessory-popup--right .content {
  padding: 0 2rem;
}
.accessory-popup--right .subtext {
  color: #ff0000;
  display: block;
  margin-bottom: 1rem;
}