// ********** HERO CONTENT ********** //
.home-slider-container {
    margin-bottom: 0;
    .slick-slide {
        position: relative;
    }
}

.home-slider-img {
    width: 100%;
}

.hero {
    position: relative;
    @include breakpoint(medium only) {
        margin-top: 6rem;
    }
    @include breakpoint(large up) {
        margin-top: 8rem;
    }
}

.hero-bkg {
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    @include breakpoint(medium up) {
        background-size: cover;
    }
}

.hero-overlay {
    padding: 0.5rem;
    background-color: #000;
    &-content {
        display: flex;
        flex-direction: column;
        @media (max-width: 1023px) {
            align-items: center;
            text-align: center;
        }
    }
    @include breakpoint(large up) {
        position: absolute;
        top: 15%;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        background-color: transparent;
    }
    &-info {
        display: inline-block;
        background-color: rgba(0, 0, 0, 1);
        border-radius: 4px;
        padding: 0.5rem;
        color: $white;
        margin-bottom: 1.5rem;
        max-width: 500px;
        width: 100%;
        @include breakpoint(medium up) {
            margin: 0 0 1rem;
            padding: 1rem;
        }
        @include breakpoint(large up) {
            background-color: rgba(0, 0, 0, 0.6);
        }
        &--small {
            font-size: 1rem;
            font-weight: 400;
            margin: 0;
            @include breakpoint(medium up) {
                font-size: 1.43rem;
            }
        }
        &--medium {
            font-size: 1.7rem;
            font-style: italic;
            @include breakpoint(medium up) {
                font-size: 2.4rem;
            }
        }
        &--large {
            color: $white;
            font-size: 2rem;
            font-style: italic;
            margin: 0;
            @include breakpoint(medium up) {
                font-size: 4.6rem;
            }
        }
    }
    &-header {
        font-style: italic;
        font-size: 2rem;
        @include breakpoint(large up) {
            font-size: 3.5rem;
        }
    }
    &-desc {
        margin: 0;
        font-weight: 400;
        line-height: 1.2;
    }
}

.hero-btn {
    align-self: flex-start;
    @include breakpoint(medium up) {
        display: inline-block;
    }
    @media (max-width: 1023px) {
        margin: 0 auto 1rem;
    }
}