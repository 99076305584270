.header {
  position: relative;
  z-index: 1;

  &--home {
    @include breakpoint(medium up) {
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }

  }

  &__top {
    background-color: rgba(0, 0, 0, 1);

    &--home {
      @include breakpoint(large up) {
        background-color: rgba(0, 0, 0, 1);
      }
    }
  }

  &__bot {
    background-color: $white;
  }


  .dropdown.menu.large-horizontal>li.is-dropdown-submenu-parent>a {
    padding-right:1rem;
  }
  .dropdown.menu.vertical>li.opens-right>a:after {
    border:0;
  }
  .dropdown.menu.large-horizontal>li.opens-right>.is-dropdown-submenu {
    margin:0;
    list-style: none;
    a:hover {
      border-bottom:0;
    }
  }
}

// mobile header
.is-drilldown-submenu.is-active {
  margin:0;
}
.is-drilldown-submenu-parent>a:after {
  border-left-color:$red;
}
.js-drilldown-back>a:before {
  border-right-color:$red;
}
.is-drilldown-submenu.is-active,.is-submenu-item.is-drilldown-submenu-item {
  a {
    display:block;
  }
}

.top-bar {
  a {
    font-size: 1rem;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
  }
}

.logo {
  text-align: center;

  @include breakpoint(large up) {
    width: auto;
    text-align: left;

    &--padding {
      min-width: 140px;
      height: 5px;
    }

    &__link {
      position: absolute;
    }
  }
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  &__block {
    line-height: 1.2;
  }

  &__header {
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1;
    margin: 0;
  }

  &__link {
    font-size: 0.9rem;
  }
}

.header-statement {
  font-size: 0.8rem;
  font-style: italic;
  font-weight: 700;
  line-height: 1.3;
  color: #fff;
  margin: 0.5rem 0;

  @include breakpoint(medium up) {
    font-size: 0.9rem;
    line-height: 1.5;
  }
}

.header-top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0;

  @include breakpoint(large) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.header-top-left {
  display: flex;
  flex-direction: column;
}

.phone-number {
  display: inline-block;

  &__icon {
    color: $primary-color;
    border: 3px solid red;
    border-radius: 25%;
    height: 1.7rem;
    width: 1.7rem;
    font-size: 1rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(medium up) {
      height: 2.2rem;
      width: 2.2rem;
      font-size: 1.5rem;
    }
  }

  &__text {
    color: $white;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1;

    @media (max-width: 320px) {
      font-size: 1rem;
    }

    @include breakpoint(medium up) {
      font-size: 2.2rem;
      vertical-align: sub;
    }
  }
}

.header-top-right {
  display: flex;
  justify-content: space-between;
}

.nav-btn {
  font-size: 2rem;
  margin: 0 1.5rem;

  @include breakpoint(large up) {
    display: none;
  }
}

.account-links {
  background-color: $dark-gray;
  border-radius: 50%;
  height: 2.1rem;
  width: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.2rem;

  @media (max-width: 320px) {
    height: 1.7rem;
    width: 1.7rem;
  }
}

.nav {
  background-color: $white;

  &--padding {
    @include breakpoint(small) {
      padding: 0;
    }

    @include breakpoint(large up) {
      padding-left: 156px;
    }
  }

  &--gray {
    background-color: $light-gray;

    @include breakpoint(large up) {
      background-color: $white;
    }
  }

  &--hidden {
    @include breakpoint(large up) {
      display: none !important;
    }
  }
}

.menu {
  li a {
    border-bottom: 1px solid #bbb;
    padding: 0.7rem 1.5rem;
    color: $black;
    font-size: 1rem;

    @include breakpoint(medium) {
      width: 100%;
    }

    @include breakpoint(large up) {
      border-bottom: none;
      font-size: 1.2rem;
      font-weight: 700;
      padding: .7rem 0.5rem;

      &:hover .center-icon {
        border-color: $primary-color;
      }
    }

    &:hover:not(.search-link) {
      border-bottom: 4px solid $primary-color;
    }
  }
}

.search-item {
  position: relative;

  @include breakpoint(large up) {
    &:after {
      content: "";
      height: 60%;
      width: 1px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translatey(-50%);
      background-color: $light-gray;
    }
  }

  &__text {
    font-size: 0.9rem;
    font-weight: initial;
  }
}

.search-form {
  display: none;
  position: relative;
  left: 0;
  width: 100%;

  @include breakpoint(large up) {
    position: absolute;
    width: 423px;
    left: -254px;
  }

  &__select {
    margin: 0;
  }

  &__btn {
    max-height: 2.5rem;
  }
}

.center-icon {
  display: inline-flex;

  @include breakpoint(medium up) {
    border-radius: 50%;
    height: 2.1rem;
    width: 2.1rem;
    align-items: center;
    justify-content: center;
    border: 2px solid $medium-gray;
    font-size: 0.9rem;

    &__icon {
      margin: 0 !important;
    }
  }
}


/* Media Queries */

@media screen and (max-width: 1023px) {
  header {
    padding: 0;

    .top-bar {

      .top-bar-left,
      .top-bar-right {
        border-right: 0px;
        width: 100%;
      }

      .top-bar-right {
        padding: 10px 20px 20px;
      }

      .top-bar-left {
        li {
          padding-left: 20px;
          padding-right: 20px;
          border-bottom: 1px solid #eeeeee;
        }

        ul ul div div {
          border-right: 0;
          margin: 0;

          &:last-child {
            border: none;
          }
        }

        .dropdown.menu .submenu li {
          padding-left: 0;
          padding-right: 0;
          border-bottom: 0;
          border-top: 1px solid #eeeeee;
        }
      }
    }
  }

  .menu-icon::after {
    background-color: #999999;
    box-shadow: 0 7px 0 #999999, 0 14px 0 #999999;
  }

  .menu>li {
    display: inherit;
  }

  .dropdown.menu .submenu {
    position: relative;
    border: 0;
  }
}