.wheel-filter {
	text-align: center;
  @include breakpoint (medium) {
    display:flex;
    align-items: center;
  }
	&__header {
		font-size:1rem;
		display:inline-block;
		margin: 0;
	}
	&__dropdown {
		display: inline-block;
		margin: 0 0.5rem;
      select {
        @include breakpoint (medium) {
          margin:0;
        }
      }
	}
	&__btn {
		margin: 0;
	}
}

.filters {
    &__header {
        font-family: 'Roboto', sans-serif;
        font-size: 1.1rem;
        padding: 30px 0;
        text-transform: uppercase;
    }
    &__select {
        background: #3f4851;
        padding: 1.5rem .8rem .8rem;
        select {
            text-transform: uppercase;
            background-color: #e1e1e1;
            font-weight: 500;
        }
    }
    &__button {
        background-color: #e1e1e1;
        color: #000;
        font-weight: 500;
        max-height: 2.45rem;
        width: 100%;
    }
}