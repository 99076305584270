#error-page {
  $primary-color:red;
  $gray6:#666666;
  $grayC:#cccccc;
    // content
    .page-content {
        background-image: url("/img/utility/error-bg\ \(2\).png");
        background-position: center 5%;
        background-repeat: no-repeat;
        background-size: 60%;
        background-position: center 5px;
        padding: 75px;
        margin-top: 75px;
        position: relative;
      
        h3 {
          font-size: 3rem;
          font-weight: 1000;
          color: black;
          margin-top: 2rem;
        }
        a,.button {
          transition: all .3s ease-in-out;
        }
        p {
          font-weight: 400;
          color: $black;
          font-size: 1.1rem;
          margin-bottom:3rem;
          a {
            color:$primary-color;
            font-weight:600;
            &:hover {
              color:$gray6;
            }
          }

        }
        ul {
          list-style: none;
          margin-left:0;
          margin-bottom:2rem;
        }
        .button {
          max-width:260px;
          width:100%;
          &:hover {
            color:$gray6;
            color:$grayC;
          }
        }

      }
    }