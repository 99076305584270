.main-image {
  margin-bottom: 0;
  img {
    margin: 0 auto;
  }
}

.alt-images {
  .slick-track {
    margin: 0 auto;
  }
  &--retailer {
    @include breakpoint(medium up) {
      .slick-track {
        width: 100%!important;
        margin-top: 3rem;
      }
    }
  }
  @include breakpoint(small) {
    .slick-dots {
      position: relative!important;
      text-align: center;
      bottom: 0;
      margin: 0;
    }
  }
  .slick-slide:focus {
    outline: 0;
  }
  a {
    margin-right: 0;
    padding: .7rem;
  }
  img {
    transition: transform .3s ease-in-out;
    &:hover {
      backface-visibility: hidden;
      transform: scale(1.08);
    }
  }
}